<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <div class="container">
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="/work1">妹妹資料新增</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/work2">妹妹資料修改</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/work3">地區設定</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/work4">國籍設定</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/work5">店家設定</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/work6">服務項目設定</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
